import './Products.scss';
import package1 from '../../assets/products/gum1.png';
import package2 from '../../assets/products/gum2.png';
import package3 from '../../assets/products/gum3.png';
import {Box, Flex, Heading, SimpleGrid, Text} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";
import GermanPackages from '../../lang/de/products.json';
import SwissPackages from '../../lang/ch/products.json';
import EnglishPackages from '../../lang/en/products.json';
import FrenchPackages from '../../lang/fr/products.json';
import DutchPackages from '../../lang/nl/products.json';
import IrishPackages from '../../lang/ie/products.json';
import NorwegianPackages from '../../lang/no/products.json';
import ItalianPackages from '../../lang/it/products.json';
import DanishPackages from '../../lang/dk/products.json';
import PolishPackages from '../../lang/pl/products.json';
import FinishPackages from '../../lang/fi/products.json';
import SpanishPackages from '../../lang/es/products.json';

function Products(props) {
    const images = [];
    images['package1'] = package1;
    images['package2'] = package2;
    images['package3'] = package3;

    const whichPackage = () => {
        switch (true) {
            case props.totalDays >= 1 && props.totalDays <= 14:
                return 'package1';
            case props.totalDays >= 15 && props.totalDays <= 33:
                return 'package2';
            case props.totalDays > 33:
                return 'package3';
            default:
                return 'package3';
        }
    };

    const getImage = (product) => {
        return images[product];
    };

    let packages = {
        de: GermanPackages,
        at: GermanPackages,
        ch: SwissPackages,
        en: EnglishPackages,
        ie: IrishPackages,
        nl: DutchPackages,
        fr: FrenchPackages,
        no: NorwegianPackages,
        it: ItalianPackages,
        dk: DanishPackages,
        pl: PolishPackages,
        fi: FinishPackages,
        es: SpanishPackages
    };

    let product = packages[props.locale][whichPackage()];

    const currentUrl = window.location.toString();

    if (currentUrl.search('/ie') !== -1) {
        product = packages['ie'][whichPackage()];
    }

    return (
        <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Box pl={10}
                 pr={10}
                 display={{ md: "flex" }}
                 alignItems="center">
                <img src={getImage(whichPackage())} alt=""/>
            </Box>
            <Box pt={3}>
                <Box textAlign={['center', 'left']}>
                    <Heading as="h3" size="xl">{product.heading}</Heading>
                    <Heading as="h4" size="md" mb={3}>{product.subheading}</Heading>
                </Box>

                <Text fontSize={'md'}>{product.description}</Text>

                <Flex mt={2} p={2} background={'#e5e4e0'}>
                    <Box textAlign={'left'} w={'40%'}>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>{product.price}</Text>
                    </Box>
                    <Box textAlign={'right'} w={'60%'}>
                        <Text fontSize="12"><FormattedMessage id="product.bottlePrice"/></Text>
                        <Text fontSize="14">{product.totalPrice}</Text>
                    </Box>
                </Flex>
            </Box>
        </SimpleGrid>
    )
}

export default Products;
